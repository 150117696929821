import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MaskInput from 'react-maskinput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Alert,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import PaymentIcon from '@mui/icons-material/Payment';
import PinIcon from '@mui/icons-material/Pin';
import EmailIcon from '@mui/icons-material/Email';
import MoneyIcon from '@mui/icons-material/Money';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
const CryptoJS = require('crypto-js');
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const cardNumberMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='0000-0000-0000-0000'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);
const securityCodeMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='0000'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);
const donationFormat = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix='฿'
      />
    );
  }
);
interface State {
  cardNumber: string;
  securityCode: string;
  donation: string;
  processingFee: string;
  firstName: string;
  lastName: string;
  country: string;
  region: string;
  address: string;
  address2: string;
  city: string;
  postalCode: string;
  email: string;
  type: string;
  donationType: string;
  programType: string;
  expirationMonth: string;
  expirationYear: string;
  isProcessingFeeAdded: boolean;
  makeDonationAnonymous: boolean;
  lang: String;
  comment: string;
}
const Donate = () => {
  const [isShowProgramTypeError, setIsShowProgramTypeError] =
    React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [isShowDonationError, setIsShowDonationError] = React.useState(false);
  const [isShowTypeError, setIsShowTypeError] = React.useState(false);
  const [isShowFirstNameError, setIsShowFirstNameError] = React.useState(false);
  const [isShowLastNameError, setIsShowLastNameError] = React.useState(false);
  const [isShowEmailError, setIsShowEmailError] = React.useState(false);
  const [isShowCardNumberError, setIsShowCardNumberError] =
    React.useState(false);
  const [isShowExpirationMonthError, setIsShowExpirationMonthError] =
    React.useState(false);
  const [isShowExpirationYearError, setIsShowExpirationYearError] =
    React.useState(false);
  const [isShowSecurityCodeError, setIsShowSecurityCodeError] =
    React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = React.useState(false);
  const [showMessageError, setShowMessageError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const [values, setValues] = React.useState<State>({
    cardNumber: '',
    securityCode: '',
    donation: '',
    processingFee: '',
    firstName: '',
    lastName: '',
    country: '',
    region: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    email: '',
    type: 'once',
    donationType: '',
    programType: '',
    expirationMonth: '',
    expirationYear: '',
    makeDonationAnonymous: false,
    isProcessingFeeAdded: true,
    lang: '',
    comment: '',
  });
  const [selectedDonation, setSelectedDonation] = React.useState('300');

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };
  const [program, setProgram] = React.useState('');

  const handleSelectProgramChange = (event: SelectChangeEvent) => {
    setIsShowProgramTypeError(false);
    setProgram(event.target.value);
    setValues({
      ...values,
      ['programType']: event.target.value as string,
    });
  };

  const handleChangeSelectedDonation = (
    event: React.MouseEvent<HTMLElement>,
    selectedDonation: string
  ) => {
    setIsShowTypeError(false);
    if (selectedDonation !== 'other') {
      // calcFee(Number(selectedDonation));
      setValues({
        ...values,
        ['donation']: selectedDonation,
      });
    } else {
      setValues({
        ...values,
        ['donation']: '',
      });
    }

    setSelectedDonation(selectedDonation);
  };
  const handleChangeDonation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowDonationError(false);
    // calcFee(Number(event.target.value));
    // setValues({
    //   ...values,
    //   [event.target.name]: event.target.value,
    // });

    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (event.target.value === '' || re.test(event.target.value)) {
      console.log(event.target.value);
      if (Number(event.target.value) <= 100000) {
        setValues({
          ...values,
          [event.target.name]: event.target.value as string,
        });
      } else {
        alert('Maximum donation: 100,000 Thai Baht (THB) per individual.');
      }
    }
  };

  const handleChangeCardNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowCardNumberError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    // event.target.setAttribute('autocomplete', 'off');
  };
  const handleChangeExpirationMonth = (event: SelectChangeEvent) => {
    setIsShowExpirationMonthError(false);
    setValues({
      ...values,
      ['expirationMonth']: event.target.value as string,
    });
  };
  const handleChangeExpirationYear = (event: SelectChangeEvent) => {
    setIsShowExpirationYearError(false);
    setValues({
      ...values,
      ['expirationYear']: event.target.value as string,
    });
  };
  const handleChangeSecurityCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowSecurityCodeError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    // event.target.setAttribute('autocomplete', 'off');
  };
  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setShowMessageSuccess(false);
    setIsShowFirstNameError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowLastNameError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowEmailError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const donateNow = async () => {
    try {
      console.log('selectedDonation-->', selectedDonation);
      console.log('selectedDonation-->', selectedDonation);

      const data = {
        donation:
          selectedDonation === 'other' &&
          values.donation !== undefined &&
          values.donation !== ''
            ? values.donation
            : selectedDonation,
        processingFee: '',
        firstName: values.firstName,
        lastName: values.lastName,
        country: '',
        region: '',
        address: '',
        address2: '',
        city: '',
        postalCode: '',
        email: values.email,
        type: 'once',
        donationType: '',
        programType: values.programType,
        cardNumber: CryptoJS.AES.encrypt(
          values.cardNumber,
          '5xrl3hsp23fe1wcpx18'
        ).toString(),
        securityCode: CryptoJS.AES.encrypt(
          values.securityCode,
          '5xrl3hsp23fe1wcpx18'
        ).toString(),
        expirationMonth: values.expirationMonth,
        expirationYear: values.expirationYear,
        lastFour:
          '***' + values.cardNumber.substr(values.cardNumber.length - 4),
        makeDonationAnonymous: false,
        isProcessingFeeAdded: true,
        lang: localStorage.getItem('wglang')
          ? localStorage.getItem('wglang')
          : '',
        comment: '',
      };
      console.log('data--->', data);

      const getInvolvedDonate = 'http://localhost:5000/api/v1/donations/donate';

      // const getInvolvedDonate =
      //   'https://dolphin-app-uexao.ondigitalocean.app/api/v1/donations/donate';

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Max-Age': '0',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'Authorization',
      };

      const donateResponce = await axios
        .post(getInvolvedDonate, data, {
          headers: headers,
        })
        .then(function (response) {
          console.log(response);
          setShowLoader(false);
          if (response.data.success) {
            console.log('selectedDonation-->', selectedDonation);
            console.log('values-->', values);
            setValues({
              cardNumber: '',
              securityCode: '',
              donation: '',
              processingFee: '',
              firstName: '',
              lastName: '',
              country: '',
              region: '',
              address: '',
              address2: '',
              city: '',
              postalCode: '',
              email: '',
              type: 'once',
              donationType: '',
              programType: '',
              expirationMonth: '',
              expirationYear: '',
              isProcessingFeeAdded: true,
              makeDonationAnonymous: false,
              lang: '',
              comment: '',
            });
            setShowMessageSuccess(true);
            setShowMessageError(false);
            setBtnDisabled(false);
            setTimeout(() => {
              setShowMessageSuccess(false);
            }, 5000);
          } else {
            setBtnDisabled(false);
            setShowMessageSuccess(false);
            setMessageError(
              response.data.data.code + ': ' + response.data.data.message
            );
            setShowMessageError(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          setBtnDisabled(false);
        });
    } catch (error) {
      console.log('error--->', error);
    }
  };
  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + '../assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Donate</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Get Involved</span>
                    </li>
                    <li>
                      <span>Donate</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-area pt-20 pb-90'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 mb-30'>
                <div className='team-details pl-30'>
                  <h3>Your help turns into smiles.</h3>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <FormControl required style={{ width: '100%' }}>
                      <InputLabel id='demo-simple-select-required-label'>
                        Select Program
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-required-label'
                        id='demo-simple-select-required'
                        value={values.programType}
                        label='Select Program *'
                        onChange={handleSelectProgramChange}
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'power-of-mental-health-for-youth'}>
                          Power of Mental Health for Youth
                        </MenuItem>
                      </Select>
                      {isShowProgramTypeError === true ? (
                        <div style={{ paddingTop: 5 }}>
                          <Alert severity='error'>
                            Program Type cannot be empty
                          </Alert>
                        </div>
                      ) : (
                        <div></div>
                      )}{' '}
                    </FormControl>
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <ToggleButtonGroup
                      value={selectedDonation}
                      exclusive
                      fullWidth={true}
                      onChange={handleChangeSelectedDonation}
                      aria-label='text alignment'
                    >
                      <ToggleButton value='300' aria-label='300'>
                        ฿ 300
                      </ToggleButton>
                      <ToggleButton value='600' aria-label='600'>
                        ฿ 600
                      </ToggleButton>
                      <ToggleButton value='900' aria-label='900'>
                        ฿ 900
                      </ToggleButton>
                      <ToggleButton value='other' aria-label='other'>
                        Other
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {isShowTypeError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>Donation cannot be empty</Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {selectedDonation === 'other' ? (
                    <>
                      <br></br>
                      <div className='contacts-icon contactss-name'>
                        <TextField
                          required
                          value={values.donation}
                          fullWidth={true}
                          id='outlined-required'
                          label='Enter your Donation'
                          onChange={handleChangeDonation}
                          name='donation'
                          helperText='Maximum donation: 100,000 Thai Baht (THB) per
                          individual.'
                          InputProps={{
                            inputComponent: donationFormat as any,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <MoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {isShowDonationError === true ? (
                          <div style={{ paddingTop: 5 }}>
                            <Alert severity='error'>
                              Donation cannot be empty
                            </Alert>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}

                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <TextField
                      required
                      fullWidth={true}
                      id='outlined-required'
                      label='First Name'
                      value={values.firstName}
                      onChange={handleChangeFirstName}
                      // autoComplete="none"
                      name='firstName'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isShowFirstNameError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          First Name cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <TextField
                      required
                      fullWidth={true}
                      id='outlined-required'
                      label='Last Name'
                      value={values.lastName}
                      onChange={handleChangeLastName}
                      // autoComplete="none"
                      name='lastName'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isShowLastNameError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          Last Name cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <TextField
                      required
                      fullWidth={true}
                      id='outlined-required'
                      label='Email'
                      value={values.email}
                      // autoComplete="none"
                      onChange={handleChangeEmail}
                      name='email'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isShowEmailError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>Email cannot be empty</Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    {/* <MaskInput
                      //onChange={onChange}
                      maskChar='_'
                      mask={mask}
                      alwaysShowMask
                      //size={20}
                    /> */}

                    <TextField
                      required
                      fullWidth={true}
                      id='outlined-required'
                      label='Credit or Debit Card Number'
                      value={values.cardNumber}
                      onChange={handleChangeCardNumber}
                      name='cardNumber'
                      InputProps={{
                        inputComponent: cardNumberMask as any,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <PaymentIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isShowCardNumberError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          Card Number cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>

                  <div className='contacts-icon contactss-name'>
                    <FormControl
                      variant='outlined'
                      // className={classes.formControl}
                      style={{ width: '100%' }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Expiration Month
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        required
                        autoComplete='none'
                        value={values.expirationMonth}
                        onChange={handleChangeExpirationMonth}
                        label='Expiration Month'
                        name='expirationMonth'
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'01'}>01 - January</MenuItem>
                        <MenuItem value={'02'}>02 - February</MenuItem>
                        <MenuItem value={'03'}>03 - March</MenuItem>
                        <MenuItem value={'04'}>04 - April</MenuItem>
                        <MenuItem value={'05'}>05 - May</MenuItem>
                        <MenuItem value={'06'}>06 - June</MenuItem>
                        <MenuItem value={'07'}>07 - July</MenuItem>
                        <MenuItem value={'08'}>08 - August</MenuItem>
                        <MenuItem value={'9'}>09 - September</MenuItem>
                        <MenuItem value={'10'}>10 - October</MenuItem>
                        <MenuItem value={'11'}>11 - November</MenuItem>
                        <MenuItem value={'12'}>12 - December</MenuItem>
                      </Select>
                    </FormControl>
                    {isShowExpirationMonthError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          Expiration Month cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>

                  <div className='contacts-icon contactss-name'>
                    <FormControl
                      variant='outlined'
                      // className={classes.formControl}
                      style={{ width: '100%' }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Expiration Year
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        required
                        autoComplete='none'
                        value={values.expirationYear}
                        onChange={handleChangeExpirationYear}
                        label='Expiration Year'
                        name='expirationYear'
                      >
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'2023'}>2023</MenuItem>
                        <MenuItem value={'2024'}>2024</MenuItem>
                        <MenuItem value={'2025'}>2025</MenuItem>
                        <MenuItem value={'2026'}>2026</MenuItem>
                        <MenuItem value={'2027'}>2027</MenuItem>
                        <MenuItem value={'2028'}>2028</MenuItem>
                        <MenuItem value={'2029'}>2029</MenuItem>
                        <MenuItem value={'2030'}>2030</MenuItem>
                        <MenuItem value={'2031'}>2031</MenuItem>
                        <MenuItem value={'2032'}>2032</MenuItem>
                        <MenuItem value={'2033'}>2033</MenuItem>
                        <MenuItem value={'2034'}>2034</MenuItem>
                        <MenuItem value={'2035'}>2035</MenuItem>
                        <MenuItem value={'2036'}>2036</MenuItem>
                        <MenuItem value={'2037'}>2037</MenuItem>
                        <MenuItem value={'2038'}>2038</MenuItem>
                        <MenuItem value={'2039'}>2039</MenuItem>
                        <MenuItem value={'2040'}>2040</MenuItem>
                      </Select>
                    </FormControl>
                    {isShowExpirationYearError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          Expiration Year cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    <TextField
                      required
                      fullWidth={true}
                      id='outlined-required'
                      label='Security Code Number'
                      value={values.securityCode}
                      onChange={handleChangeSecurityCode}
                      name='securityCode'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <PinIcon />
                          </InputAdornment>
                        ),
                        inputComponent: securityCodeMask as any,
                      }}
                    />
                    {isShowSecurityCodeError === true ? (
                      <div style={{ paddingTop: 5 }}>
                        <Alert severity='error'>
                          Security Code Number cannot be empty
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-icon contactss-name'>
                    {showLoader === true ? (
                      <div>
                        <Alert style={{ marginTop: 10 }} severity='info'>
                          <b>
                            please wait for a while you donation transaction is
                            under progress
                          </b>
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {showMessageSuccess === true ? (
                      <div>
                        <Alert style={{ marginTop: 10 }} severity='success'>
                          <b>
                            {' '}
                            Thank you so much for your generous donation,
                            detailed receipt has been emailed to you . you will
                            receive email shortly
                          </b>
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {showMessageError === true ? (
                      <div>
                        <Alert style={{ marginTop: 10 }} severity='error'>
                          <b>{messageError}</b>
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <br></br>
                  <div className='contacts-us-form-button text-center'>
                    <button
                      className='c-btn'
                      disabled={btnDisabled}
                      type='submit'
                      onClick={() => {
                        if (
                          values.programType === undefined ||
                          values.programType === null ||
                          values.programType === ''
                        ) {
                          setIsShowProgramTypeError(true);
                        }

                        if (
                          selectedDonation === 'other' &&
                          (values.donation === undefined ||
                            values.donation === null ||
                            values.donation === '')
                        ) {
                          setIsShowDonationError(true);
                        }
                        if (
                          selectedDonation === undefined ||
                          selectedDonation === null ||
                          selectedDonation === ''
                        ) {
                          setIsShowTypeError(true);
                        }
                        if (
                          values.firstName === undefined ||
                          values.firstName === null ||
                          values.firstName === ''
                        ) {
                          setIsShowFirstNameError(true);
                        }
                        if (
                          values.lastName === undefined ||
                          values.lastName === null ||
                          values.lastName === ''
                        ) {
                          setIsShowLastNameError(true);
                        }
                        if (
                          values.email === undefined ||
                          values.email === null ||
                          values.email === ''
                        ) {
                          setIsShowEmailError(true);
                        }
                        if (
                          values.cardNumber === undefined ||
                          values.cardNumber === null ||
                          values.cardNumber === ''
                        ) {
                          setIsShowCardNumberError(true);
                        }
                        if (
                          values.expirationMonth === undefined ||
                          values.expirationMonth === null ||
                          values.expirationMonth === ''
                        ) {
                          setIsShowExpirationMonthError(true);
                        }
                        if (
                          values.expirationYear === undefined ||
                          values.expirationYear === null ||
                          values.expirationYear === ''
                        ) {
                          setIsShowExpirationYearError(true);
                        }
                        if (
                          values.securityCode === undefined ||
                          values.securityCode === null ||
                          values.securityCode === ''
                        ) {
                          setIsShowSecurityCodeError(true);
                        }
                        if (
                          values.programType !== undefined &&
                          values.programType !== null &&
                          values.programType !== '' &&
                          ((selectedDonation === 'other' &&
                            values.donation !== undefined &&
                            values.donation !== null &&
                            values.donation !== '') ||
                            (selectedDonation !== 'other' &&
                              selectedDonation !== '' &&
                              selectedDonation !== null &&
                              selectedDonation !== undefined)) &&
                          values.firstName !== undefined &&
                          values.firstName !== null &&
                          values.firstName !== '' &&
                          values.lastName !== undefined &&
                          values.lastName !== null &&
                          values.lastName !== '' &&
                          values.email !== undefined &&
                          values.email !== null &&
                          values.email !== '' &&
                          values.cardNumber !== undefined &&
                          values.cardNumber !== null &&
                          values.cardNumber !== '' &&
                          values.securityCode !== undefined &&
                          values.securityCode !== null &&
                          values.securityCode !== '' &&
                          values.expirationMonth !== undefined &&
                          values.expirationMonth !== null &&
                          values.expirationMonth !== '' &&
                          values.expirationYear !== undefined &&
                          values.expirationYear !== null &&
                          values.expirationYear !== ''
                        ) {
                          setShowMessageSuccess(false);
                          setShowMessageError(false);
                          setBtnDisabled(true);
                          setShowLoader(true);
                          setSelectedDonation('300');
                          setIsShowTypeError(false);
                          setProgram('');
                          donateNow();
                        }
                        // alert('submitted successfully');
                      }}
                    >
                      {' '}
                      Donate Now{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 mb-30'>
                <div className='team-details pl-30'></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Donate;
